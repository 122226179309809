export default {
    "시작하기": "시작하기",
    "시작하기": "시작하기",
    "다운로드": "다운로드",
    "RAYTeams Cloud Platform is building an amazing ecosystem specialized in Dental Services." : "RAYTeams Cloud Platform은 Dental Service에 있어 뛰어난 생태계를 구축할 수 있습니다.",
    "Join the RAYTeams Cloud and get started." : "RAYTeams Cloud를 시작해보세요!",
    "Explore more" : "더 알아보기",
    "Open Platform for Anyone" : "오픈 플랫폼",
    "RAYTeams Cloud Platform is an open platform that anyone can participate in." : "RAYTeams Cloud Platform은 누구나 참여가능한 오픈 플랫폼입니다.",
    "Simple & Powerful Workflows" : "심플하고 강력한 워크플로우",
    "Create, use, or provide the workflow for your organization as an app." : "다양한 앱들을 통해서 워크플로우를 이용하여 쉽게 사용이 가능합니다.",
    "Global Partner & Community" : "Global Partner & Community",
    "Try RAYTeams Apps, provided or collaborated with Global Patner." : "다양한 RAYTeams App을 통해서 Global Patner와 협업해보세요.",
    "Customize & Flexable Platform" : "유연한 커스터마이징 플랫폼",
    "Provides powerful customization for hospitals and clinic." : "매우 강력한 커스터마아징 기능을 제공합니다.",
    "RAYTeams CLIENT DOWNLOAD" : "RAYTeams Client 다운로드",
    "The RAYTeams Client is the default client software required when using the RAYTeams Cloud Platform." : "RAYTeam Client는 RAYTeams Cloud Platform을 이용하기 위한 필수 소프트웨어 입니다.",
    "Client Download" : "클라이언트 다운로드",
    "MANUAL" : "메뉴얼",
    "Do you need any questions or instructions for installing RAYTeams?" : "RAYTeams 관련하여 궁금한 사항이 있나요?",
    "Please check out the manual." : "메뉴얼을 확인해보세요.",
    "more view" : "더보기",
    "WHAT'S NEW" : "WHAT'S NEW",
    "Let's take a look at the new features of RAYTeams." : "RAYTeams의 새로운 기능을 살펴보세요.",
    "Please check out the what's new." : "달라진점 확인해보기",
    "Whatever the Dental Service needs," : "Dental Service의 모든 것,",
    "RAYTeams Cloud Platform provides an excellent service experience." : "RAYTeams Cloud Platform은 탁월한 서비스 경험을 제공합니다.",
    "RAYTeams Cloud Platform is..." : "RAYTeams Cloud Platform은...",
    "RAYTeams Cloud Platform is an open platform that anyone can participate in." : "RAYTeams Cloud Platform은 누구나 사용가능한 오픈 플랫폼입니다.",
    "Anyone can easily create and introduce the RAYTeams App to multiple users." : "누구나 쉽게 이용할 수 있으며, 다양한 RAYTeams App을 통해서 다른 사용자들과 협업할 수 있습니다.",
    "To that end, RAYTEAMS Cloud" : "RAYTeams Cloud",
    "There are several workflows in dentistry and dental lab." : "치과에서는 많은 치료 관련 워크플로우가 있습니다.",
    "There are also workflows based on treatment methods such as correction, prosthetics, denture, etc., and there are various workflows such as pore delivery, schedule management, and simple to do management." : "보철, 교정, 덴처 등 다양한 치료 방법에 대한 효과적인 워크플로가 준비되어있습니다.",
    "Workflows provided by several related apps are available, and you can also create and use or provide a workflow that fits your organization directly into an app." : "관련된 여러 워크플로우는 앱을 사용하여 효과적이고 직관적으로 사용할 수 있습니다.",
    "The RAYTeams Cloud Platform is based in 13 regions worldwide." : "RAYTeams Cloud Platform은 전세계 13개의 지역에 서버를 두고 있습니다.",
    "A variety of services are available using RAYTeams Apps provided or collaborated with Global Patner." : "글로벌 파트너 또는 RAYTeams App에서 제공하는 다양한 서비스를 이용할 수 있습니다.",
    "In addition, it provides a powerful community so that you can share and view effective and diverse information." : "또한, 강력한 커뮤니티를 통해서 다양한 정보도 얻을 수 있습니다.",
    "The RAYTeams Cloud Platform provides powerful customization for large hospitals and large air stations. You can also build another ecosystem of your own." : "RAYTeams Cloud Paltform은 강력한 커스터마이징을 제공합니다. 대형 기공소/치과의 경우, 더욱더 다양한 기능이 제공됩니다. 대형 기관의 경우, 자신만의 Platform 생태계를 구성할 수도 있습니다.",
    "You can build a RAYTEAMS Cloud Platform specifically for a specific hospital or a specific laboratory to provide services." : "RAYTeams Cloud Platform에는 파터너를 위한 특정 기공소/치과에 대한 매니지먼트 기능도 포함되어 있습니다.",
    "Email verification has been completed.": "이메일 인증이 완료되었습니다.",
    "Email verification failed.": "이메일 인증에 실패했습니다.",
    "Email verification": "이메일 인증",
    "We are currently in the process of email verification.": "이메일 인증을 진행하고 있습니다.",
    "Verification has been completed. You may now close this window.": "인증이 완료되었습니다. 이 창은 이제 닫아도 됩니다.",
    "You have accessed this page through an incorrect path.": "잘못된 경로로 이 페이지에 접근했습니다.",
    "You have accessed this page through an invalid method.": "올바르지 않은 방법으로 이 페이지에 접근했습니다.",
    "Please try again in a moment.": "잠시 후 다시 시도해 주세요.",
    "The email verification time has expired. Please request another verification email.": "이메일 인증 시간이 만료되었습니다. 다시 인증메일을 받아주세요.",
    "There is no requested verification.": "요청된 인증이 없습니다.",
    "We couldn't find the user who requested the verification.": "인증을 요청한 사용자를 찾을 수 없습니다.",
    "An error has occurred. Please try again later.": "오류가 발생했습니다. 잠시 후 다시 시도해주세요.",
    "This email is different from the one you requested.": "요청한 이메일과 다른 이메일입니다.",
    "The current request is incorrect. If you have requested email verification multiple times, please check the latest email.": "현재 요청은 잘못된 요청입니다. 만약 여러번 인증 메일을 요청했다면 최신의 메일을 확인해주세요.",
    "구독 관리" : "구독 관리",
    "Subscription": "구독",
	"Purchase": "구매",
    "This product is paid periodically after purchase.": "이 상품은 구매 후 주기적으로 결제됩니다.",
    "Right now, this product is being applied at a discounted price.": "지금 이 상품은 할인된 가격이 적용 중입니다.",
    "Paypal": "페이팔",
    "Toss": "토스",
    "Includes Trial": "평가판 포함됨",
    "Without Trial": "평가판 미포함",
    "This product is available for free trial for {{trialPeriod}} days.": "이 상품은 {{trialPeriod}}일 동안 무료로 평가판 사용이 가능합니다.",
    "After a {{trialPeriod}}-day trial, billing starts, then recurs every {{subPeriod}} days.": "{{trialPeriod}}일의 무료 평가판 기간 이후에 결제가 시작되며, 이후로는 {{subPeriod}}일마다 결제됩니다.",
    "Billing every {{subPeriod}} days": "{{subPeriod}}일 마다 결제",
    "Check {{appTitle}} details": "{{appTitle}} 상세 정보 확인",
    "Billing cycle": "결제 주기",
    "Whether to include a trial": "평가판 포함 여부",
    "Payment amount": "결제 금액",
    "Payment Type": "결제 유형",
    "Product information": "상품 정보",
    "Do you want to cancel the payment and go to the detailed screen of the app?": "결제를 취소하고 앱의 상세 화면으로 이동하시겠습니까?",
    "Billing every {{subPeriod}} days.": "{{subPeriod}}일 마다 결제",
    "You can cancel the subscription for this product at any time.": "이 상품은 언제든 구독을 취소할 수 있습니다.",
    "Your account has already utilized the free trial period on {{date}}.": "귀하의 계정은 이미 {{date}}에 무료 체험 기간을 사용했습니다.",
    "Please wait...": "기다려 주세요",
    "Business number": "사업자등록번호",
    "Mail order sales approval number": "통신판매업신고번호",
    "CEO": "대표이사",
    "Sang Chul Lee": "이상철",
    "Email": "이메일",
    "Address": "주소",
    "12 Floor, TWOSUN WORLD Bldg, 221 Pangyoyeok-ro, Bundang-gu Seongnam-si, Gyeonggi-do Republic of Korea": "경기도 성남시 분당구 판교역로 221(삼평동 672) 투썬월드빌딩 12층",
    "Phone": "대표전화",
    "+82 (31) 605-1000" : "031-605-1000",
    "RAY": "주식회사 레이",
    "Notice": "공지사항",
    "Privacy Policy": "개인정보처리방침",
    "Terms and Conditions" : "이용약관",
    "The automatic payment date may vary slightly depending on the payment gateway provider." : "자동 결제일시는 결제 대행 업체에 따라 조금씩 차이가 발생할 수 있습니다.",
    "Minor bugs have been fixed and usability has been improved.": "사소한 버그가 수정되었고 사용성이 향상되었습니다.",
    "Open Platform for Healthcare Professionals": "의료(치과) 종사자들을 위한 오픈 플랫폼",
    "RAYTeams Cloud Platform is an open platform for healthcare professionals": "RAYTeams는 의료(치과) 종사자들을 위한 강력한 오픈 플랫폼입니다.",
    "payment.result.ok": "확인",
    "{{trialPeriodStr}} 이후 {{price}}{{currency}} / {{paymentCycle}} 결제": "이후 {{price}}{{currency}} / {{paymentCycle}} 결제",
    "Please wait a second...": "잠시만 기다려주세요...",
    "AGREE": "동의",
    "Don't show this window again." : "이 창을 다시 열지 않음",
    "Status": "상태",
    "Payment Status": "결제 상태",
    "Collected items": "수집 항목",
    "Purpose of use": "이용 목적",
    "Institution's name": "기관 이름",
    "Search institutions to create a case": "케이스 생성을 위한 기관 조회",
    "Display Institution's name for chatting": "채팅을 위한 기관 이름 표시",
    "(Optional) I agree to receive marketing information.": "(선택) 나는 마케팅 정보 수신에 동의합니다.",
    "Agreement to receive marketing information": "마케팅 정보 수신 동의",
    "Refusal to receive marketing information": "마케팅 정보 수신 거절",
    "Would you like to consent to receiving marketing information?": "마케팅 정보 수신에 동의하시겠습니까?",
    "Would you like to refuse consent for receiving marketing information?": "마케팅 정보 수신 동의를 거절하시겠습니까?",
    "Agreed": "동의됨",
    "Disagreed": "미동의",
    "button.marketing.toDisagree": "마케팅 수신 동의 철회",
    "button.marketing.toAgree": "마케팅 수신 동의",
    "Receive Marketing Information": "마케팅 정보 수신",
    "Please authenticate below to subscribe for an extension.": "구독을 연장 하려면 아래 인증을 해주세요.",
    "Subscription extension": "구독 연장",
    "Subscription extension has been completed.": "구독 연장이 완료되었습니다.",
    "The subscription extension has already been completed.": "이미 구독 연장이 완료되었습니다.",
    "No subscription extension request": "구독 연장 신청이 없습니다.",
    "License Key": "라이선스 키",
    "License Registration Info":"라이선스 등록 정보",
    "No Registration record":"등록 이력이 없습니다.",
    "Registration Date":"등록 일시",
    "Last Used": "최근 사용일",
    "Show": "보기",
    "Hide": "감추기",
    "Copy": "복사",
    "Service Page":"서비스 페이지",
}
